.form{
    .form-input{
        font-family: 'PT Serif', serif;
        font-size: 1rem;
        width: 100%;
        padding: 10px 0;
        background: none;
        border: none;
        border-bottom: 1px solid #666;
        color: #666;
        outline: none;
        transition: border-color .2s;
        margin-top: 15px;
    }
    
    .form-button {
        background: none;
        text-transform: uppercase;
        font-size: 1rem;
        cursor: pointer;
        outline: none;
        margin-top: 10px;
        border: black 1px solid;
        padding: 10px;
        border-radius: 10px;
        font-family: 'PT Serif', serif;
      }
    .form-button:hover{
        background-color: $light-grey;
    }
    .success{
        a{
            text-decoration:none;
            border-bottom: 2px solid $light-grey;
            box-shadow: inset 0 -4px 0 $light-grey;
            color: inherit;
            transition: background 0.1s cubic-bezier(.33,.66,.66,1);
          }
    }
}
